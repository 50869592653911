import Vue from 'vue'
import VueRouter from 'vue-router'
// import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import parametersRoutes from './routes/parametersRoutes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        // pageTitle: 'Accueil',
        // breadcrumb: [
        //   {
        //     text: 'Accueil',
        //     active: true,
        //   },
        // ],
        role: ['USER', 'ADMIN', 'ANPC', 'POINTFOCAL'],
      },
    },
    ...parametersRoutes,

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/politique-confidentialite',
      name: 'politique-confidentialite',
      component: () => import('@/views/PolitiqueConfidentialite.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  console.log(isLoggedIn)
  // console.log(canNavigate(to))
  // if (!canNavigate(to)) {
  //   console.log('ici')
  //   // Redirect to login if not logged in
  //   // if (!isLoggedIn) {
  //   //   return next({ name: 'login' })
  //   // }

  //   // console.log('true')
  //   // // If logged in => not authorized
  //   // return next({ name: 'misc-not-authorized' })
  // }

  if (to.matched.some(record => record.meta.role)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (isLoggedIn === null) {
      console.log('...')
      next({
        path: '/login',
      })
    } else {
      const userData = getUserData()
      console.log(userData.role)
      console.log(to.meta.role)
      if (to.meta.role.includes(userData.role) && userData.status === 1) {
        console.log('ici')
        next()
      } else {
        next({ name: 'misc-not-authorized' })
      }
    }
  } else {
    console.log('ici 1')
    next() // make sure to always call next()!
  }
  console.log('ici 2')
  // Redirect if logged in
  // if (isLoggedIn) {
  //   console.log('ici 2')
  //   const userData = getUserData()
  //   if (userData) {
  //     return next({
  //       path: '/',
  //     })
  //   }

  //   return next({
  //     path: '/login',
  //   })

  //   // next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }

  // return next()
})

export default router
