export default {
  SET_MATRICULES(state, payload) {
    state.matricules = payload
  },
  SET_DROPDOWN_CATEGORIES(state, payload) {
    state.dropDownCategories = payload
  },
  ADD_MATRICULE_ITEM(state, payload) {
    state.matricules.push(payload)
  },

  UPDATE_CATEGORY(state, category) {
    const categoryIndex = state.categories.findIndex(p => p.id === category.id)
    Object.assign(state.categories[categoryIndex], category)
  },

  REMOVE_CATEGORY(state, itemId) {
    const ItemIndex = state.categories.findIndex(p => p.id === itemId)

    state.categories.splice(ItemIndex, 1)
  },
  SET_COSTUMERS(state, payload) {
    state.costumers = payload
  },
  UPDATE_COSTUMER(state, costumer) {
    const costumerIndex = state.costumers.findIndex(p => p.id === costumer.id)
    Object.assign(state.costumers[costumerIndex], costumer)
  },
  ADD_COSTUMER_ITEM(state, payload) {
    state.costumers.push(payload)
  },

  REMOVE_COSTUMER(state, itemId) {
    const ItemIndex = state.costumers.findIndex(p => p.id === itemId)

    state.costumers.splice(ItemIndex, 1)
  },
  SET_DOCUMENTS(state, payload) {
    state.documents = payload
  },
  SET_ORDER_HISTORIES(state, payload) {
    state.histories = payload
  },

  ADD_DOCUMENT_ITEM(state, payload) {
    state.documents.push(payload)
  },
  ADD_ORDER_HISTORY_ITEM(state, payload) {
    state.histories.push(payload)
  },
  SET_ORDER(state, payload) {
    state.order = payload
  },

  UPDATE_ORDER(state, order) {
    state.order = order
  },
  FETCH_PRODUCTS(state, list) {
    state.products = list
  },
  ADD_PRODUCT_ITEM(state, produits) {
    state.products = produits
  },
  SET_PRODUCT(state, product) {
    state.product = product
  },
  SET_DROPDOWN_PRODUCTS(state, payload) {
    state.dropDownProducts = payload
  },

  SET_INPUTS(state, payload) {
    state.inputs = payload
  },

  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload
  },
  SET_DROPDOWN_SUPPLIERS(state, payload) {
    state.dropDownSuppliers = payload
  },
  ADD_SUPPLIER_ITEM(state, supplier) {
    state.suppliers.push(supplier)
  },
  REMOVE_SUPPLIER(state, itemId) {
    const ItemIndex = state.suppliers.findIndex(p => p.id === itemId)

    state.suppliers.splice(ItemIndex, 1)
  },
  UPDATE_SUPPLIER(state, supplier) {
    const supplierIndex = state.suppliers.findIndex(p => p.id === supplier.id)
    Object.assign(state.suppliers[supplierIndex], supplier)
  },
  // SET_DROPDOWN_CATEGORIES (state, payload) {
  //
  //   state.dropDownCategories = payload
  //
  // },
  // ADD_CATEGORY_ITEM (state, payload) {
  //   state.categories.push(payload)
  // },
  //
  //
  // UPDATE_CATEGORY (state, category) {
  //   const categoryIndex = state.categories.findIndex((p) => p.id === category.id)
  //   Object.assign(state.categories[categoryIndex], category)
  // },
  //
  // REMOVE_CATEGORY (state, itemId) {
  //   const ItemIndex = state.categories.findIndex((p) => p.id === itemId)
  //
  //   state.categories.splice(ItemIndex, 1)
  // },
}
