export default {
  matricules: [],
  dropDownCategories: [],
  costumers: [],
  costumer: null,
  documents: [],
  order: null,
  histories: [],
  products: [],
  dropDownProducts: [],
  product: null,
  inputs: [],
  input: null,
  suppliers: [],
  dropDownSuppliers: [],

}
