import state from '@/store/auth/moduleAuthState'
import actions from '@/store/auth/moduleAuthActions'
import mutations from '@/store/auth/moduleAuthMutations'
import getters from '@/store/auth/moduleAuthGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
