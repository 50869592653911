import axios from 'axios'

const baseURL = process.env.VUE_APP_BACKEND_URL || 'https://prealerteinondation.bj/backend'
// const baseURL = process.env.VUE_APP_BACKEND_URL || 'http://104.152.49.245/backend'
// const baseURL = process.env.VUE_APP_BACKEND_URL || 'http://127.0.0.1:3000'

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
})
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    console.log(originalRequest)

    if (
      error.response.status === 401
    ) {
      console.log('401')
      window.location.href = '/login'
      return Promise.reject(error)
    }
    if (
      error.response.status === 403
    ) {
      window.location.href = '/not-authorized'
      return Promise.reject(error)
    }

    return Promise.reject(error)
  },
)
export default axiosInstance
