export default {

  SET_BALISES(state, payload) {
    state.balises = payload
  },
  SET_DROPDOWN_FINALCATEGORIES(state, payload) {
    state.dropDownFinalCategories = payload
  },
  ADD_BALISE_ITEM(state, payload) {
    console.log(payload)
    state.balises.push(payload)
  },

  UPDATE_BALISE(state, balise) {
    const baliseIndex = state.balises.findIndex(p => p.id === balise.id)
    Object.assign(state.balises[baliseIndex], balise)
  },

  REMOVE_BALISE(state, itemId) {
    const ItemIndex = state.balises.findIndex(p => p.id === itemId)

    state.balises.splice(ItemIndex, 1)
  },
  SET_ZONES(state, payload) {
    state.zones = payload
  },
  UPDATE_ZONE(state, zone) {
    const zoneIndex = state.zones.findIndex(p => p.id === zone.id)
    Object.assign(state.zones[zoneIndex], zone)
  },
  ADD_ZONE_ITEM(state, payload) {
    state.zones.push(payload)
  },

  REMOVE_ZONE(state, itemId) {
    const ItemIndex = state.zones.findIndex(p => p.id === itemId)

    state.zones.splice(ItemIndex, 1)
  },
  SET_PREALERTES(state, payload) {
    state.prealertes = payload
  },
  SET_PREALERTE(state, payload) {
    state.prealerte = payload
  },
  SET_INFORMATIONS(state, list) {
    state.informations = list
  },
  ADD_INFORMATION_ITEM(state, payload) {
    state.informations.push(payload)
  },
  UPDATE_INFORMATION(state, information) {
    const informationIndex = state.informations.findIndex(p => p.id === information.id)
    Object.assign(state.informations[informationIndex], information)
  },
  SET_WEBUSERS(state, payload) {
    state.webUsers = payload
  },
  UPDATE_WEBUSER(state, webUser) {
    const webUserIndex = state.webUsers.findIndex(p => p.id === webUser.id)
    Object.assign(state.zones[webUserIndex], webUser)
  },
  SET_DEPARTEMENTS(state, payload) {
    state.departements = payload
  },
  ADD_DEPARTEMENT_ITEM(state, payload) {
    state.departements.push(payload)
  },
  UPDATE_DEPARTEMENT(state, departement) {
    const departementIndex = state.departements.findIndex(p => p.id === departement.id)
    Object.assign(state.departements[departementIndex], departement)
  },
  SET_COMMUNES(state, payload) {
    state.communes = payload
  },
  ADD_COMMUNE_ITEM(state, payload) {
    state.communes.push(payload)
  },
  UPDATE_COMMUNE(state, commune) {
    const communeIndex = state.communes.findIndex(p => p.id === commune.id)
    Object.assign(state.departements[communeIndex], commune)
  },
  SET_settingS(state, payload) {
    state.settings = payload
  },
  ADD_setting_ITEM(state, payload) {
    state.settings.push(payload)
  },
  UPDATE_setting(state, setting) {
    const settingIndex = state.settings.findIndex(p => p.id === setting.id)
    Object.assign(state.settings[settingIndex], setting)
  },
  SET_ARRONDISSEMENTS(state, payload) {
    state.arrondissements = payload
  },
  ADD_ARRONDISSEMENT_ITEM(state, payload) {
    state.arrondissements.push(payload)
  },
  UPDATE_ARRONDISSEMENT(state, arrondissement) {
    const arrondissementIndex = state.arrondissements.findIndex(p => p.id === arrondissement.id)
    Object.assign(state.arrondissements[arrondissementIndex], arrondissement)
  },
  SET_RECEVERS(state, payload) {
    state.recevers = payload
  },
  ADD_RECEVER_ITEM(state, payload) {
    state.recevers.push(payload)
  },
  UPDATE_RECEVER(state, recever) {
    const receverIndex = state.recevers.findIndex(p => p.id === recever.id)
    Object.assign(state.recevers[receverIndex], recever)
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload
  },
  ADD_SETTING_ITEM(state, payload) {
    state.settings.push(payload)
  },
  UPDATE_SETTING(state, setting) {
    const settingIndex = state.settings.findIndex(p => p.id === setting.id)
    Object.assign(state.settings[settingIndex], setting)
  },

}
