/* eslint-disable no-underscore-dangle */
import { getUserData } from '@/auth/utils'
import axiosInstance from '../../libs/axios'
import index from '../../@core/http/endpoint/index'

export default {

  fetchBaliseListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.balises}`)
        .then(response => {
          console.log(axiosInstance.defaults.headers.common)
          commit('SET_BALISES', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addBaliseItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.balises,
        item).then(response => {
        console.log(response.data)
        commit('ADD_BALISE_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  updateBaliseItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.balises}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_BALISE', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  fetchZonesListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.zones}`)
        .then(response => {
          console.log(response)
          commit('SET_ZONES', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addZoneItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.zones,
        item).then(response => {
        console.log(response.data)
        commit('ADD_ZONE_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },

  updateZoneItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.zones}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_ZONE', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  fetchPrealertesListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.alerts}`)
        .then(response => {
          console.log(axiosInstance.defaults.headers.common)

          const user = getUserData()
          console.log(user.role)
          const prealertes = []
          if (user.role === 'POINTFOCAL') {
            console.log('ici')
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < response.data.length; i++) {
              // console.log('prezone  ', response.data[i].balise.zone._id)

              // eslint-disable-next-line no-underscore-dangle
              if (response.data[i].balise.zone._id === user.zone) {
                prealertes.push(response.data[i])
              }
            }
            commit('SET_PREALERTES', prealertes)
          } else {
            commit('SET_PREALERTES', response.data)
          }

          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  getPrealertItem({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.alerts}${id}`)
        .then(response => {
          console.log('tata')
          commit('SET_PREALERTE', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log('toto')
          reject(error)
        })
    })
  },
  fetchInformationsListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.informations}`)
        .then(response => {
          console.log(axiosInstance.defaults.headers.common)
          commit('SET_INFORMATIONS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addInformationItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('title', item.title)
      formData.append('text', item.text)
      formData.append('file', item.file)
      formData.append('typeInfo', item.typeInfo)
      formData.append('createdBy', item.createdBy)
      formData.append('createdAt', item.createdAt)
      formData.append('updatedAt', item.updatedAt)
      axiosInstance.post(index.informations,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
        commit('ADD_INFORMATION_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },

  updateInformationItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('title', item.title)
      formData.append('text', item.text)
      formData.append('file', item.file)
      formData.append('createdBy', item.createdBy)
      formData.append('createdAt', item.createdAt)
      formData.append('updatedAt', item.updatedAt)
      axiosInstance.put(`${index.informations}${item.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        // console.log(response.data)
        commit('UPDATE_INFORMATION', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  fetchWebUserListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.webUser}`)
        .then(response => {
          console.log(axiosInstance.defaults.headers.common)
          commit('SET_WEBUSERS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  updateWebUserItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.webUser}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_WEBUSER', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  getWebUserItem({ commit }, id) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.webUser}${id}`)
        .then(response => {
          console.log('tata')
          commit('SET_WEBUSER', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log('toto')
          reject(error)
        })
    })
  },
  fetchDepartementsListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.departements}`)
        .then(response => {
          // console.log(axiosInstance.defaults.headers.common)
          commit('SET_DEPARTEMENTS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addDepartementItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.departements,
        item).then(response => {
        console.log(response.data)
        commit('ADD_DEPARTEMENT_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  updateDepartementItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.departements}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_DEPARTEMENT', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },

  fetchCommunesListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.communes}`)
        .then(response => {
          // console.log(axiosInstance.defaults.headers.common)
          commit('SET_COMMUNES', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addCommuneItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.communes,
        item).then(response => {
        console.log(response.data)
        commit('ADD_COMMUNE_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  updateCommuneItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.communes}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_COMMUNE', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },

  fetchArrondissementsListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.arrondissements}`)
        .then(response => {
          // console.log(axiosInstance.defaults.headers.common)
          commit('SET_ARRONDISSEMENTS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  fetchFilteredArrondissementsListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.arrondissements}filtered`)
        .then(response => {
          // console.log(axiosInstance.defaults.headers.common)
          commit('SET_ARRONDISSEMENTS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addArrondissementItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.arrondissements,
        item).then(response => {
        console.log(response.data)
        commit('ADD_ARRONDISSEMENT_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  updatearrondissementItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.arrondissements}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_ARRONDISSEMENT', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  fetchReceversListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.recevers}`)
        .then(response => {
          // console.log(axiosInstance.defaults.headers.common)
          commit('SET_RECEVERS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addReceverItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.recevers,
        item).then(response => {
        console.log(response.data)
        commit('ADD_RECEVER_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  updateReceverItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.recevers}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_RECEVER', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  fetchSettingsListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`${index.settings}`)
        .then(response => {
          // console.log(axiosInstance.defaults.headers.common)
          commit('SET_SETTINGS', response.data)

          resolve(response)
        })
        .catch(error => {
          console.log(error.code)
          reject(error)
        })
    })
  },
  addSettingItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(index.settings,
        item).then(response => {
        console.log(response.data)
        commit('ADD_SETTING_ITEM', response.data)
        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
  updateSettingItem({ commit }, item) {
    console.log(item)
    return new Promise((resolve, reject) => {
      axiosInstance.put(`${index.settings}${item.id}/`, item).then(response => {
        // console.log(response.data)
        commit('UPDATE_SETTING', response.data)

        resolve(response)
      })
        .catch(error => { reject(error) })
    })
  },
}
