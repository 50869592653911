export default {
  zones: [],
  balises: [],
  prealertes: [],
  prealerte: [],
  informations: [],
  webUsers: [],
  departements: [],
  communes: [],
  arrondissements: [],
  recevers: [],
  settings: [],
  // order: null,
  // histories: [],
  // products: [],
  // dropDownProducts: [],
  // product: null,
  // inputs: [],
  // input: null,
  // suppliers: [],
  // dropDownSuppliers: [],

}
