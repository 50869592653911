import state from '@/store/mainApp/moduleMainAppState'
import actions from '@/store/mainApp/moduleMainAppActions'
import mutations from '@/store/mainApp/moduleMainAppMutations'
import getters from '@/store/mainApp/moduleMainAppGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
