const link = {
  zones: '/api/v1/zones/',
  informations: '/api/v1/informations/',
  alerts: '/api/v1/alerts/',
  subCategories: '/api/v1/subcategories/',
  balises: '/api/v1/balises/',
  login: '/api/v1/webusers/login',
  signup: '/api/v1/webusers/signup',
  webUser: '/api/v1/webusers/',
  departements: '/api/v1/departements/',
  communes: '/api/v1/communes/',
  settings: '/api/v1/settings/',
  arrondissements: '/api/v1/arrondissements/',
  recevers: '/api/v1/recevers/',

}

export default link
